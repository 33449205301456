.benefits-section {
  background-color: #00BF63;
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 110px;
  margin-left: 130px;
}

.book-info h2, .book-info h1 {
  font-size: 36px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.book-info h2 {
  color: #f0f0f0;
}

.book-info h1 {
  color: #000000;
}

.book-info .installments {
  font-size: 29px;
  font-weight: bold;
  margin-bottom: 5px;
}

.book-info .total-price {
  font-size: 20px;
  color: #f0f0f0;
  margin-bottom: 5px;
}

.book-info .buy-now-button {
  margin-top: 25px;
  background-color: #ff4d4d;
  border: none;
  padding: 28px 40px;
  color: white;
  font-size: 28px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.book-info .buy-now-button:hover {
  background-color: #cc0000;
  transform: scale(1.05);
}

.book-image-2 {
  max-width: 30%;
  margin-left: 55px;
  margin-right: 90px;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.book-image-2:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.book-image-2 img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.book-info:focus, .book-image-2:focus {
  outline: 2px solid #00BF63;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .benefits-section {
    flex-direction: column;
  }

  .book-image-2 {
    max-width: 80%;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .book-info {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    text-align: center;
  }

  .book-info h2, .book-info h1 {
    font-size: 28px; /* Ajusta o tamanho do título para telas menores */
  }

  .book-info .installments {
    font-size: 24px;
  }

  .book-info .total-price {
    font-size: 18px;
  }

  .book-info .buy-now-button {
    padding: 15px 30px;
    font-size: 20px;
    align-self: center;
  }
}

/* Ajustes para telas muito pequenas */
@media (max-width: 480px) {
  .book-info h2, .book-info h1 {
    font-size: 24px;
  }

  .book-info .installments {
    font-size: 20px;
  }

  .book-info .total-price {
    font-size: 16px;
  }

  .book-info .buy-now-button {
    padding: 10px 20px;
    font-size: 18px;
  }

  .book-image-2 {
    max-width: 90%;
  }
}
