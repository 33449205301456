.benefits-section {
  background-color: #00BF63; /* Fundo verde para a seção de benefícios */
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefits-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1090px;
  width: 100%;
}

.benefits-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 45%;
}

.benefit-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  flex: 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.benefit-item:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.benefit-item h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
}

.benefit-item p {
  font-size: 14px;
  color: #666;
}

.book-image {
  max-width: 30%;
  margin-left: 5px;
  margin-right: 90px;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.book-image:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.book-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.benefit-item:focus, .book-image:focus {
  outline: 2px solid #00BF63;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .benefits-content {
    flex-direction: column;
    align-items: center;
    text-align: center; /* Centraliza o conteúdo em telas menores */
  }

  .benefits-list {
    max-width: 100%;
    flex-direction: column;
    gap: 15px; /* Aumenta o espaço entre os itens em telas menores */
  }

  .book-image {
    max-width: 80%; /* Aumenta a imagem do livro para ocupar mais espaço em dispositivos menores */
    margin-top: 20px;
    margin-left: 0; /* Remove o espaço lateral em dispositivos pequenos */
    margin-right: 0;
  }
}

/* Ajustes para telas muito pequenas */
@media (max-width: 480px) {
  .benefit-item h3 {
    font-size: 16px; /* Reduz o tamanho do título em telas pequenas */
  }

  .benefit-item p {
    font-size: 12px; /* Ajusta o texto dos benefícios para melhor legibilidade em telas menores */
  }

  .book-image {
    max-width: 90%; /* Faz a imagem ocupar quase toda a largura da tela */
  }
}
