/* Adiciona uma fonte para o estilo de blog */
@import url('https://fonts.googleapis.com/css2?family=Lobster:wght@400;700&family=Roboto:wght@400;700&display=swap');

.blog-header {
  display: flex;
  flex-direction: column; /* Alinha o título e a navbar em coluna */
  align-items: center; /* Centraliza o conteúdo horizontalmente */
  border-bottom: 1px solid #ddd; /* Borda inferior do cabeçalho */
  padding: 20px; /* Adiciona espaçamento ao redor */
  background-color: #f9f9f9; /* Cor de fundo clara */
  background-size: cover; /* Cobre todo o cabeçalho com a imagem */
  background-position: center; /* Centraliza a imagem */
  color: #333; /* Cor do texto */
  height: auto;
}

.blog-title {
  text-align: center; /* Alinha o texto ao centro */
  margin-bottom: 20px; /* Espaço abaixo do título */
}

.blog-title h1 {
  font-family: 'Lobster', cursive; /* Fonte do título */
  font-size: 36px;
  color: #151414; /* Cor verde */
  margin: 0; /* Remove margem padrão */
}

.blog-title p {
  font-family: 'Roboto', sans-serif; /* Fonte para o subtítulo */
  font-size: 18px;
  color: #666; /* Cor do subtítulo */
  margin: 0; /* Remove margem padrão */
}

.blog-navbar {
  display: flex;
  justify-content: center; /* Centraliza a navbar horizontalmente */
  width: 100%;
}

.blog-navbar-links {
  list-style: none; /* Remove os marcadores da lista */
  display: flex; /* Exibe os itens em linha */
  gap: 20px; /* Espaço entre os itens */
}

.blog-navbar-links li {
  display: inline-block;
}

.blog-navbar-links a {
  text-decoration: none;
  color: #666; /* Cor dos links */
  font-size: 18px;
  padding: 10px 20px; /* Espaço interno dos links */
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transição suave */
}

.blog-navbar-links a:hover,
.blog-navbar-links a:focus {
  color: #fff; /* Cor do texto ao passar o mouse ou focar */
  background-color: #00BF63; /* Cor de fundo ao passar o mouse */
}

.blog-navbar-links a:active {
  color: #fff; /* Cor do texto ao serem clicados */
  background-color: #00BF63; /* Cor de fundo ao serem clicados */
}

.blog-title:focus, .blog-navbar-links:focus {
  outline: 2px solid #00BF63;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .blog-header {
    padding: 10px; /* Reduz o espaçamento em telas pequenas */
  }

  .blog-title h1 {
    font-size: 28px; /* Diminui o tamanho do título */
  }

  .blog-title p {
    font-size: 16px; /* Diminui o tamanho do subtítulo */
  }

  .blog-navbar {
    justify-content: center; /* Garante que o menu fique centralizado */
  }

  .blog-navbar-links {
    flex-direction: column; /* Alinha os links em coluna em telas pequenas */
    gap: 10px;
    align-items: center; /* Centraliza os links verticalmente */
  }

  .blog-navbar-links a {
    font-size: 16px; /* Reduz o tamanho do texto dos links */
    padding: 8px 16px; /* Reduz o espaçamento interno dos links */
    width: 100%; /* Faz os links ocuparem a largura total */
    text-align: center; /* Garante que o texto dentro dos links fique centralizado */
  }
}

/* Responsividade para telas muito pequenas */
@media (max-width: 480px) {
  .blog-title h1 {
    font-size: 24px; /* Diminui ainda mais o tamanho do título */
  }

  .blog-title p {
    font-size: 14px; /* Ajusta o tamanho do subtítulo */
  }

  .blog-navbar-links a {
    font-size: 14px; /* Ajusta o tamanho do texto dos links */
    padding: 6px 12px; /* Ajusta o espaçamento interno dos links */
  }
}
