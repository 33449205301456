.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  background-color: #f4f4f4;
  text-align: center;
  border-bottom: 2px solid #ddd;
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.text-content {
  max-width: 50%;
}

.text-content h1 {
  font-size: 29px;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.text-content h2 {
  font-size: 28px;
  color: #00BF63;
  margin-bottom: 20px;
}

.text-content h3 {
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6;
}

.cta-button {
  display: inline-block;
  padding: 28px 40px;
  font-size: 28px;
  color: #fff;
  background-color: #ff4d4d;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border: none;
}

.cta-button:hover {
  background-color: #cc0000;
  transform: scale(1.05);
}

.image-content {
  max-width: 50%;
  padding: 0 20px;
}

.image-content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cta-button:focus, .image-content:focus {
  outline: 2px solid #00BF63;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .banner {
    flex-direction: column;
    padding: 40px 10px; /* Reduz o padding em telas menores */
  }

  .banner-content {
    flex-direction: column;
    align-items: center;
  }

  .text-content, .image-content {
    max-width: 100%;
    padding: 0;
    margin-bottom: 20px; /* Adiciona espaçamento entre os elementos */
  }

  .text-content h1 {
    font-size: 28px; /* Diminui o tamanho do título em telas menores */
  }

  .text-content h2 {
    font-size: 24px;
  }

  .text-content p {
    font-size: 16px; /* Ajusta o tamanho do texto para telas pequenas */
  }

  .cta-button {
    font-size: 18px; /* Reduz o tamanho do botão em telas menores */
    padding: 10px 20px; /* Ajusta o padding do botão para caber melhor */
  }

  .image-content img {
    border-radius: 5px; /* Suaviza o efeito das bordas arredondadas em telas menores */
  }
}

@media (max-width: 480px) {
  .text-content h1 {
    font-size: 24px; /* Tamanho ainda menor para telas muito pequenas */
  }

  .text-content p {
    font-size: 14px; /* Reduz ainda mais o tamanho do texto */
  }

  .cta-button {
    font-size: 16px;
    padding: 8px 15px;
  }

  /* Adiciona um espaçamento maior entre a imagem e o botão */
  .text-content, .image-content {
    margin-bottom: 30px;
  }
}
