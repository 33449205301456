.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
}

.footer-social,
.footer-contact {
  flex: 1;
  padding: 0 20px;
  text-align: center;
}

.footer-social h4,
.footer-contact h4 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #00BF63;
}

.social-links,
.contact-links {
  list-style: none;
  padding: 0;
}

.social-links li,
.contact-links li {
  margin-bottom: 10px;
}

.social-links a,
.contact-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.social-links a:hover,
.contact-links a:hover {
  color: #00BF63;
}

.social-links a svg,
.contact-links a svg {
  margin-right: 10px;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  margin-top: 40px;
  font-size: 14px;
  color: #bbb;
  border-top: 1px solid #555;
}

.social-links:focus,
.footer-contact:focus {
  outline: 2px solid #00BF63;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-social,
  .footer-contact {
    margin-bottom: 20px;
    padding: 0; /* Remove padding lateral em telas pequenas */
  }

  .footer-bottom {
    margin-top: 20px; /* Reduz espaço superior para melhor ajuste em telas menores */
  }
}

/* Ajustes para telas muito pequenas */
@media (max-width: 480px) {
  .footer-social h4,
  .footer-contact h4 {
    font-size: 16px; /* Diminui o tamanho do título */
  }

  .social-links a,
  .contact-links a {
    font-size: 14px; /* Ajusta o tamanho do texto nos links */
  }

  .footer-bottom {
    font-size: 12px; /* Diminui o tamanho do texto de direitos autorais */
  }
}
