.author-section {
  background-color: #f9f9f9; /* Fundo leve para destacar a seção */
  padding: 50px 20px;
}

.author-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px; /* Aumenta o espaçamento entre o texto e a imagem */
  max-width: 1200px;
  margin: 0 auto;
}

.author-text {
  flex: 1;
  font-family: 'Segoe UI', sans-serif;
  color: #333;
}

.author-text h2 {
  color: #00BF63; /* Cor verde do título */
  font-size: 34px;
  margin-bottom: 20px;
}

.author-text p {
  font-size: 18px;
  line-height: 1.8; /* Mais espaçamento entre as linhas */
  margin-bottom: 20px;
  text-align: justify; /* Deixa o texto mais alinhado */
}

.author-image {
  flex: 0.6; /* Menor proporção para a imagem */
}

.author-image img {
  width: 80%; /* Ajusta o tamanho da imagem */
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra mais suave */
  margin: 0 auto; /* Centraliza a imagem dentro do container */
}

.author-text:focus, .author-image:focus {
  outline: 2px solid #00BF63;
}

/* Estilos Responsivos */
@media (max-width: 768px) {
  .author-container {
    flex-direction: column;
    text-align: center;
  }

  .author-image {
    margin-top: 20px;
  }

  .author-image img {
    width: 100%; /* Ajusta a imagem para ocupar 100% da largura disponível */
    max-width: 300px; /* Define uma largura máxima para a imagem */
  }

  .author-text h2 {
    font-size: 28px;
  }

  .author-text p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .author-text h2 {
    font-size: 24px; /* Reduz o tamanho do título em telas menores */
  }

  .author-text p {
    font-size: 14px; /* Reduz o tamanho do parágrafo em telas menores */
  }

  .author-section {
    padding: 30px 10px; /* Reduz o padding em telas menores */
  }
}
